.steps-content {
  min-height: 200px;
  margin-top: 16px;
  padding-top: 10px;
  text-align: center;
  background-color: #fafafa;
  border: 1px dashed #e9e9e9;
  border-radius: 2px;
}

.steps-action {
  margin-top: 24px;
}
[data-theme="dark"] .steps-content {
  margin-top: 16px;
  border: 1px dashed #303030;
  background-color: rgba(255,255,255,0.04);
  color: rgba(255,255,255,0.65);
  padding-top: 80px;
}

.header2-text-label{
  pointer-events: none;
}